import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import {
  QUESTION_NUMBERING_STYLE__ARABIC,
  QUESTION_NUMBERING_STYLE__ALPHABET,
  QUESTION_NUMBERING_STYLE__ROMAN,
  QUESTION_NUMBERING_STYLE__SMALL_ROMAN,
  QUESTION_NUMBERING_STYLE__SMALL_ALPHABET,
} from '../constants';

export function arabic(number) {
  return number.toString();
}

const letters = 'abcdefghijklmnopqrstuvwxyz';

const reverse = (array) => {
  const result = [];
  const n = array.length;
  for (let i = n - 1; i >= 0; i -= 1) {
    result.push(array[i]);
  }
  return result;
};

export function alphabet(number) {
  const n = letters.length;
  let i = number - 1;
  const chunks = [];
  for (;;) {
    const r = i % n;
    chunks.push(letters.charAt(r));
    i = (i - r) / n;
    if (i <= 0) {
      break;
    }
    i -= 1;
  }
  return reverse(chunks).join('');
}

const symbols = [['i', 'v', 'x'], ['x', 'l', 'c'], ['c', 'd', 'm'], ['m']];

const repeat = (n, str) => {
  let result = '';
  for (let i = 0; i < n; i += 1) {
    result += str;
  }
  return result;
};

export function roman(number) {
  const decimal = [];
  let i = number;
  do {
    const r = i % 10;
    decimal.push(r);
    i = (i - r) / 10;
  } while (i > 0);
  const chunks = [];
  const n = decimal.length;
  for (let j = 0; j < n; j += 1) {
    if (symbols[j]) {
      const [one, five, ten] = symbols[j];
      switch (decimal[j]) {
        case 0:
        case 1:
        case 2:
        case 3: {
          if (one) {
            chunks.push(repeat(decimal[j], one));
          }
          break;
        }
        case 4: {
          if (one && five) {
            chunks.push(`${one}${five}`);
          }
          break;
        }
        case 5:
        case 6:
        case 7:
        case 8: {
          if (one && five) {
            chunks.push(`${five}${repeat(decimal[j] - 5, one)}`);
          }
          break;
        }
        case 9: {
          if (one && ten) {
            chunks.push(`${one}${ten}`);
          }
          break;
        }
        default:
        // do nothing
      }
    }
  }
  return reverse(chunks).join('');
}

export function numbering(style, index) {
  switch (style) {
    case QUESTION_NUMBERING_STYLE__ARABIC:
      return arabic(index + 1);
    case QUESTION_NUMBERING_STYLE__ALPHABET:
      return alphabet(index + 1).toUpperCase();
    case QUESTION_NUMBERING_STYLE__SMALL_ALPHABET:
      return alphabet(index + 1);
    case QUESTION_NUMBERING_STYLE__ROMAN:
      return roman(index + 1).toUpperCase();
    case QUESTION_NUMBERING_STYLE__SMALL_ROMAN:
      return roman(index + 1);
    default:
      return `[${this.index}]`;
  }
}

/**
 * @param {number[]} integers
 */
export function getIntervals(integers) {
  const sorted = sortBy(integers);
  const n = sorted.length;
  if (n === 0) {
    return [];
  }
  if (n === 1) {
    return [[sorted[0], sorted[0]]];
  }
  const intervals = [];
  let start = sorted[0];
  let end = sorted[0];
  let i = 1;
  while (i < n) {
    const x = sorted[i];
    if (x <= end + 1) {
      end = x;
    } else {
      intervals.push([start, end]);
      start = x;
      end = x;
    }
    i += 1;
  }
  intervals.push([start, end]);
  return intervals;
}

/**
 * @param {number[]} integers
 */
export function printIntervals(integers) {
  const intervals = getIntervals(integers);
  if (intervals.length === 0) {
    return '[empty]';
  }
  return map(intervals, ([a, b]) => {
    if (a === b) {
      return `${a}`;
    }
    return `${a}-${b}`;
  }).join(', ');
}
