import {
  FILTER_TYPE__ANY_OF,
  FILTER_TYPE__PROPERTY,
  FILTER_TYPE__VARIABLE,
  FILTER_TYPE__NOT_COMPLETED,
  SORTER_TYPE__PROPERTY,
  SORTER_TYPE__VARIABLE,
} from './filters';
import {
  VARIABLE_ID__STUDY_NO,
  VARIABLE_ID__CLINICIAN,
  VARIABLE_ID__CASE_MANAGER,
  VARIABLE_ID__GENDER,
  VARIABLE_ID__GENDER_SINGAPORE,
  VARIABLE_ID__ETHNICITY,
  VARIABLE_ID__STATUS_IN_PROJECT,
  VARIABLE_ID__PHONE_HOME,
  VARIABLE_ID__PHONE_WORK,
  VARIABLE_ID__PHONE_MOBILE,
  VARIABLE_ID__PHONE_NOK_MOBILE,
  VARIABLE_ID__EMAIL_PERSONAL,
  VARIABLE_ID__EMAIL_WORK,
  VARIABLE_ID__STUDY_NO_TERMS,
} from './Variables';

export const COLOR_MOUNTAIN_MEADOW = '#1bbc9b';

export const NONE_OPTION = {
  label: '-- Please select one --',
  value: '',
};

export const BLANK_OPTION = {
  label: '',
  value: '',
};

export const YES_OR_NO_OPTIONS = [
  {
    value: true,
    label: 'Yes',
  },
  {
    value: false,
    label: 'No',
  },
];

export const PATIENTS_DIRECTORY__TABLE = 'Patients';

export const SORTING_ORDER__ASCENDING = 'Ascending';
export const SORTING_ORDER__DESCENDING = 'Descending';
export const SORTING_ORDERS = [
  SORTING_ORDER__ASCENDING,
  SORTING_ORDER__DESCENDING,
];

export const SORTING_ORDER__OPTIONS = [
  {
    value: SORTING_ORDER__ASCENDING,
    label: 'Ascending',
  },
  {
    value: SORTING_ORDER__DESCENDING,
    label: 'Descending',
  },
];

export const POSITION__LEFT = 'left';
export const POSITION__RIGHT = 'right';
export const POSITION__BOTTOM = 'bottom';
export const POSITION__TOP = 'top';

export const VALUE_AXIS_POSITIONS = [POSITION__LEFT, POSITION__RIGHT];

export const VALUE_AXIS_POSITION_OPTIONS = [
  {
    value: POSITION__LEFT,
    label: 'Left',
  },
  {
    value: POSITION__RIGHT,
    label: 'Right',
  },
];

export const CATEGORY_AXIS_SORTING__PREDEFINED = 'predefined';
export const CATEGORY_AXIS_SORTING__ASCENDING = 'ascending';
export const CATEGORY_AXIS_SORTING__DESCENDING = 'descending';
export const CATEGORY_AXIS_SORTING = [
  CATEGORY_AXIS_SORTING__PREDEFINED,
  CATEGORY_AXIS_SORTING__ASCENDING,
  CATEGORY_AXIS_SORTING__DESCENDING,
];

export const CATEGORY_AXIS_SORTING_OPTIONS = [
  {
    value: CATEGORY_AXIS_SORTING__PREDEFINED,
    label: 'Predefined',
  },
  {
    value: CATEGORY_AXIS_SORTING__ASCENDING,
    label: 'Ascending',
  },
  {
    value: CATEGORY_AXIS_SORTING__DESCENDING,
    label: 'Descending',
  },
];

export const LEGEND_POSITIONS = [
  POSITION__LEFT,
  POSITION__RIGHT,
  POSITION__BOTTOM,
  POSITION__TOP,
];

export const LEGEND_POSITION_OPTIONS = [
  {
    value: POSITION__LEFT,
    label: 'Left',
  },
  {
    value: POSITION__RIGHT,
    label: 'Right',
  },
  {
    value: POSITION__BOTTOM,
    label: 'Bottom',
  },
  {
    value: POSITION__TOP,
    label: 'Top',
  },
];

export const COLOR_PALLETE = [
  '#67b7dc',
  '#fdd400',
  '#84b761',
  '#cc4748',
  '#cd82ad',
  '#2f4074',
  '#448e4d',
  '#b7b83f',
  '#b9783f',
  '#b93e3d',
  '#913167',

  '#ffb7dc',
  '#ffd400',
  '#ffb761',
  '#ff4748',
  '#ff82ad',
  '#ff4074',
  '#ff8e4d',
  '#ffb83f',
  '#ff783f',
  '#ff3e3d',
  '#ff3167',

  '#67ffdc',
  '#fdff00',
  '#84ff61',
  '#ccff48',
  '#cdffad',
  '#2fff74',
  '#44ff4d',
  '#b7ff3f',
  '#b9ff3f',
  '#b9ff3d',
  '#91ff67',

  '#67b7ff',
  '#fdd4ff',
  '#84b7ff',
  '#cc47ff',
  '#cd82ff',
  '#2f40ff',
  '#448eff',
  '#b7b8ff',
  '#b978ff',
  '#b93eff',
  '#9131ff',
];

export const DEFAULT_RESULTS_PER_PAGE = 20;
export const MAX_NUMBER_OF_PAGES = 5;

export const DEFAULT_PROJECT_FILTERS = [
  {
    id: 'default_filter_by_patient_name_or_identifier_or_phone',
    type: FILTER_TYPE__ANY_OF,
    settings: {
      filters: [
        {
          type: FILTER_TYPE__PROPERTY,
          settings: {
            id: 'recipientId',
          },
        },
        {
          type: FILTER_TYPE__PROPERTY,
          settings: {
            id: 'recipientNameTerms',
          },
        },
        {
          type: FILTER_TYPE__PROPERTY,
          settings: {
            id: 'primaryIdentifier',
          },
        },
        {
          type: FILTER_TYPE__VARIABLE,
          settings: {
            id: VARIABLE_ID__STUDY_NO_TERMS,
          },
        },
        {
          type: FILTER_TYPE__VARIABLE,
          settings: {
            id: VARIABLE_ID__PHONE_MOBILE,
          },
        },
        {
          type: FILTER_TYPE__VARIABLE,
          settings: {
            id: VARIABLE_ID__PHONE_WORK,
          },
        },
        {
          type: FILTER_TYPE__VARIABLE,
          settings: {
            id: VARIABLE_ID__PHONE_HOME,
          },
        },
        {
          type: FILTER_TYPE__VARIABLE,
          settings: {
            id: VARIABLE_ID__PHONE_NOK_MOBILE,
          },
        },
        {
          type: FILTER_TYPE__VARIABLE,
          settings: {
            id: VARIABLE_ID__EMAIL_PERSONAL,
          },
        },
        {
          type: FILTER_TYPE__VARIABLE,
          settings: {
            id: VARIABLE_ID__EMAIL_WORK,
          },
        },
      ],
    },
    title: 'Patient name or identifier',
    tags: ['dashboard', 'directory'],
    defaultState: {
      text: '',
    },
  },
  {
    id: 'default_filter_by_not_completed_milestones',
    type: FILTER_TYPE__NOT_COMPLETED,
    settings: {},
    title: 'Not completed',
    tags: ['dashboard'],
    defaultState: {
      exclude: [],
    },
  },
  {
    id: 'default_filter_by_clinician',
    type: FILTER_TYPE__VARIABLE,
    settings: {
      id: VARIABLE_ID__CLINICIAN,
    },
    title: 'Clinician',
    tags: ['charts', 'dashboard', 'directory'],
    defaultState: {
      exclude: [],
    },
  },
  {
    id: 'default_filter_by_case_manager',
    type: FILTER_TYPE__VARIABLE,
    settings: {
      id: VARIABLE_ID__CASE_MANAGER,
    },
    title: 'Case Manager',
    tags: ['charts', 'dashboard', 'directory'],
    defaultState: {
      exclude: [],
    },
  },
  {
    id: 'default_filter_by_gender',
    type: FILTER_TYPE__ANY_OF,
    title: 'Gender',
    settings: {
      filters: [
        {
          type: FILTER_TYPE__VARIABLE,
          settings: {
            id: VARIABLE_ID__GENDER,
          },
        },
        {
          type: FILTER_TYPE__VARIABLE,
          settings: {
            id: VARIABLE_ID__GENDER_SINGAPORE,
          },
        },
      ],
    },
    tags: ['charts', 'dashboard', 'directory'],
    defaultState: {
      exclude: [],
    },
  },
  {
    id: 'default_filter_by_ethnicity',
    type: FILTER_TYPE__VARIABLE,
    title: 'Ethnicity',
    settings: {
      id: VARIABLE_ID__ETHNICITY,
    },
    tags: ['charts', 'dashboard', 'directory'],
    defaultState: {
      exclude: [],
    },
  },
  {
    id: 'default_filter_by_status',
    type: FILTER_TYPE__VARIABLE,
    title: 'Status',
    settings: {
      id: VARIABLE_ID__STATUS_IN_PROJECT,
    },
    tags: ['charts', 'dashboard', 'directory'],
    defaultState: {
      exclude: [],
    },
  },
];

export const DEFAULT_PROJECT_SORTING_OPTIONS = [
  {
    id: 'default_sort_by_patient_name',
    title: 'Name',
    type: SORTER_TYPE__PROPERTY,
    settings: {
      id: 'recipientName',
    },
    collation: {
      locale: 'en',
      strength: 1,
    },
    useInDashboard: true,
    useInDirectory: true,
  },
  {
    id: 'default_sort_by_nearest_due_date',
    title: 'Days remaining',
    type: SORTER_TYPE__PROPERTY,
    settings: {
      id: 'nearestDueDate',
    },
    useInDashboard: true,
    useInDirectory: false,
  },
  {
    id: 'default_sort_by_study_no',
    title: 'Study No',
    type: SORTER_TYPE__VARIABLE,
    settings: {
      id: VARIABLE_ID__STUDY_NO,
    },
    useInDashboard: false,
    useInDirectory: true,
  },
  {
    id: 'default_sort_by_clinician',
    title: 'Clinician',
    type: SORTER_TYPE__VARIABLE,
    settings: {
      id: VARIABLE_ID__CLINICIAN,
    },
    useInDashboard: false,
    useInDirectory: true,
  },
  {
    id: 'default_sort_by_case_manager',
    title: 'Case manager',
    type: SORTER_TYPE__VARIABLE,
    settings: {
      id: VARIABLE_ID__CASE_MANAGER,
    },
    useInDashboard: false,
    useInDirectory: true,
  },
];
